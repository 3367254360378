import React from "react";
import LightBox from "components/common/LightBox";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";

const setEbookNoticeEMail = gql`
  mutation setEbookNoticeEMail($email: String) {
    setEbookNoticeEMail(email: $email) {
      success
    }
  }
`;

@LightBox
class EbookNoticeEditEmailLightBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      email: "",
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {}

  @autobind
  submitEdit(e) {
    e.preventDefault();
    let { email } = this.state;
    if (email !== "") {
      client.jumperrwdClient
      .mutate({
        mutation: setEbookNoticeEMail,
        variables: {
          email: email,
        },
      })
      .then(async (res) => {
        if (res.data.setEbookNoticeEMail.success) {
          alert("修改成功");
          this.props.close();
          if (this.props.refetch) {
            this.props.refetch();
          }
        } else {
          alert("修改失敗");
        }
      });
    } else {
      alert("請輸入電子信箱");
    }
  }

  render() {
    return (
      <>
        <h3>修改電子信箱設定</h3>
        <div className="share">
          <form>
            <input
              type="email"
              id="username"
              placeholder=""
              value={this.state.email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
            />
            <br />
            <button type="submit" className="cancel" onClick={this.props.close}>
              取消
            </button>
            <button type="submit" onClick={this.submitEdit}>修改</button>
          </form>
        </div>
      </>
    );
  }
}

export default EbookNoticeEditEmailLightBox;
