import React from "react";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import DataList from "components/list/DataList";
import GraphqlFragment from "lib/GraphqlFragment";
import TeacherShareReadingQueryGroupComp from "components/teacher/TeacherShareReadingQueryGroupComp";
import TeacherShareReadingDisplayComp from "components/teacher/TeacherShareReadingDisplayComp";
import TeacherShareReadingAssignLightBox from "components/teacher/TeacherShareReadingAssignLightBox";

const getTeacherShareReadingList = gql`
  query getTeacherShareReadingList($searchForm: SearchForm) {
    getTeacherShareReadingList(Input: $searchForm) {
      display {
        field
        name
        position
        type
        isUnEscape
      }
      sort {
        name
        order
        field
        default
      }
      batch {
        ...getElemtn
      }
      list {
        values {
          ref {
            key
            value
          }
          resourceItem {
            journalAttachList {
              showName
              attachPath
            }
            journalURLList {
              url
              openWin
              showName
            }
            cateList
            standardno
            impactFactor
            pic
            srValue
            assignedClassList {
              schoolID
              classTitle
            }
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        hyftdToken
        userCollectionList
        sortFieldList {
          type
          order
          message
        }
        pages {
          val
          active
        }
        showNext
        showPrevious
        nFilterTotal
      }
    }
  }
  ${GraphqlFragment.defaultLayoutFragment}
`;

@inject("appStore")
@observer
class TeacherShareReadingComp extends React.Component {
  constructor(props) {
    super(props);
    let { searchForm,  } = props;
    let listType = searchForm.listType || "allShareReading";
    this.state = {
        auth: props.readerStore.auth,
        refetch: null,
        showFilter: false,
        listType: listType,
        checkedData: [],
        readingSn: "",
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {
    if (props.searchForm.listType !== this.state.listType) {
      let listType = props.searchForm.listType || "allShareReading";
      this.setState({
        showFilter: false,
        listType: listType,
        checkedData: [],
      })
    }
  }

  @autobind
  changeListType(listType) {
    let { location } = this.props;
    let params = {
      pageType: "teacherShareReading",
      listType: listType,
    };
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ refetch: refetch });
  }

  @autobind
  handleClick(type, item) {
    if (type === "assignClass") {
      this.setState({ readingSn: item.sn });
      this.teacherShareReadingAssignLightBox.popup();
    } else if (type === "addCheckedId") {
      var c = this.state.checkedData;
      if (item.checked) {
        c.push(item.value);
      } else {
        var index = c.indexOf(item.value);
        if (index !== -1) {
          c.splice(index, 1);
        }
      }

      this.setState({ checkedData: c });
    }
  }

  @autobind
  changeShowFilter(type) {
    if (type) {
      this.setState({ showFilter: true });
      document.body.classList.add("fix");
    } else {
      this.setState({ showFilter: false });
      document.body.classList.remove("fix");
    }
  }

  render() {
    let { searchForm, t } = this.props;
    let { showFilter, readingSn } = this.state;
    let listType = searchForm.listType || "allShareReading";
    searchForm.listType = listType;
    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <div className={showFilter ? "filter_search show_up" : "filter_search"}>
          <a
            className="accesskey"
            href="javascript:;"
            id="aL"
            accessKey="L"
            title={t("jumperrwd.common.leftSideArea")}>
            :::
          </a>
          <TeacherShareReadingQueryGroupComp
            ref={(c) => (this.queryGroup = c)}
            {...this.props}
            changeShowFilter={this.changeShowFilter}
            refetch={this.state.refetch}
            showFilter={showFilter}
          />
        </div>
        <div className="search_result">
          <h2>共讀書單列表</h2>
          <div className="tabSet">
            <ul className="tabs">
              <li className={listType === "allShareReading" ? "active" : ""}>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeListType("allShareReading");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeListType("allShareReading");
                    }
                  }}>
                  指派共讀書單
                </a>
              </li>
              <li className={listType === "assignedShareReading" ? "active" : ""}>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeListType("assignedShareReading");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeListType("assignedShareReading");
                    }
                  }}>
                  已分配班級共讀書單
                </a>
              </li>
            </ul>
          </div>
          <div className="list_all">
            <DataList
              query={getTeacherShareReadingList}
              variables={{
                searchForm: this.props.searchForm,
              }}
              fetchPolicy={"network-only"}
              displayComp={TeacherShareReadingDisplayComp}
              pagination={"FixedPagination"}
              getRefetch={this.getRefetch}
              handleClick={this.handleClick}
              onQueryCompleted={this.onQueryCompleted}
              showNoData={false}
              hasFilter={true}
              isChecked={(id) => {
                if (this.state.checkedData.includes(id)) return true;
                else return false;
              }}
            />
          </div>
          <TeacherShareReadingAssignLightBox
            ref={(c) => {
              this.teacherShareReadingAssignLightBox = c;
            }}
            readingSn={readingSn}
            blockClass="resourceblock classblock"
            refetch={this.state.refetch}
            {...this.props}
          />
        </div>
      </ApolloProvider>
    );
  }
}

export default TeacherShareReadingComp;
